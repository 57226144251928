//https://www.youtube.com/watch?v=HBmL_qSNx3M

//import React from 'react';
import * as React from "react";
import "./componenets/firebase";
import RootNavigation from "./navigation";
import { AppProvider } from "./screens/AppContext"; // Import the context provider
import Navigation from "./navigation/Navigation";

//import { GestureHandlerRootView } from "react-native-gesture-handler";



import { enGB, registerTranslation } from "react-native-paper-dates";
registerTranslation("en-GB", enGB);

export default function App() {
  return (
    
    <AppProvider>
      <RootNavigation />
    </AppProvider>
  );
}
