import React, { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  StyleSheet
} from 'react-native';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'TEAM';

/* ===================== DRAG & DROP COMPONENTS ===================== */

// DraggableItem: Renders a team that can be dragged.
const DraggableItem = ({ item, currentBox, moveItem }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType,
    item: { id: item.id, currentBox, team: item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [item, currentBox, moveItem]);

  return (
    <View ref={drag} style={[styles.itemBox, isDragging && styles.dragging]}>
      <Text style={styles.itemText}>{item.text}</Text>
    </View>
  );
};

// Box: Represents a group (box) that accepts dropped items.
const Box = ({ boxName, items, moveItem }) => {
  const [, drop] = useDrop(() => ({
    accept: ItemType,
    drop: (draggedItem) => {
      moveItem(draggedItem.team, draggedItem.currentBox, boxName);
    },
  }), [boxName, moveItem]);

  return (
    <View ref={drop} style={styles.box}>
      <Text style={styles.boxTitle}>{boxName}</Text>
      {items.length === 0 ? (
        <Text style={styles.emptyText}>Drop item here</Text>
      ) : (
        items.map((item) => (
          <DraggableItem
            key={`${boxName}-${item.id}`}
            item={item}
            currentBox={boxName}
            moveItem={moveItem}
          />
        ))
      )}
    </View>
  );
};

// Helper: Given an item and current assignments, return the box that contains the item.
const getItemBox = (item, assignments) => {
  for (const box in assignments) {
    if (assignments[box].find((t) => t.id === item.id)) {
      return box;
    }
  }
  return null;
}

/* ===================== SCHEDULING HELPERS ===================== */

// Generate all unique pairs from an array of team names.
function generateAllPairs(teamNames) {
  const pairs = [];
  for (let i = 0; i < teamNames.length; i++) {
    for (let j = i + 1; j < teamNames.length; j++) {
      pairs.push([teamNames[i], teamNames[j]]);
    }
  }
  return pairs;
}

// Formats a number of minutes as HH:MM.
function formatTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
}

/* 
  Helper: Reorder an array of raw matches (each match is an object with a teams array)
  so that no match immediately follows one with a team in common. If no candidate is found,
  a null value is pushed as a gap round.
*/
function reorderMatchesForGroup(matches) {
  const result = [];
  const remaining = [...matches];
  let lastTeams = new Set();

  while (remaining.length > 0) {
    const idx = remaining.findIndex(match => {
      // Choose a match whose teams do NOT intersect with lastTeams.
      return !match.teams.some(team => lastTeams.has(team));
    });
    if (idx === -1) {
      // No match found that avoids a repeat; insert a gap round (rest round)
      result.push(null);
      // Reset lastTeams so that next match is not constrained.
      lastTeams = new Set();
    } else {
      const match = remaining.splice(idx, 1)[0];
      result.push(match);
      lastTeams = new Set(match.teams);
    }
  }
  return result;
}

/* 
  Helper: Given an array of raw matches for a group, reorder them using the above function,
  then reassign sequential times (inserting a gap round when necessary). A gap round is indicated
  by an object with the flag gap: true.
*/
function reorderGroupSchedule(rawMatches, startMinutes, matchDuration, breakDuration) {
  const reordered = reorderMatchesForGroup(rawMatches);
  let currentTime = startMinutes;
  const finalSchedule = [];
  reordered.forEach(match => {
    if (match === null) {
      // Insert a gap round (no match scheduled; teams get rest)
      finalSchedule.push({ time: formatTime(currentTime), gap: true });
    } else {
      finalSchedule.push({ ...match, time: formatTime(currentTime) });
    }
    currentTime += (matchDuration + breakDuration);
  });
  return finalSchedule;
}

/* ===================== BACK-TO-BACK MARKING ===================== */

// For overall schedule views we do not mark back-to-back.
function markBackToBack(schedule) {
  return schedule;
}

// Mark back-to-back matches for group schedules only.
function markBackToBackForGroupSchedules(groupSchedules) {
  for (const group in groupSchedules) {
    const matches = groupSchedules[group];
    for (let i = 1; i < matches.length; i++) {
      // Only consider non-gap rounds.
      if (matches[i] && matches[i-1] && !matches[i].gap && !matches[i-1].gap) {
        const previousTeams = new Set(matches[i - 1].teams);
        const b2b = matches[i].teams.filter(team => previousTeams.has(team));
        if (b2b.length > 0) {
          matches[i].backToBack = true;
          matches[i].backToBackTeams = b2b;
        }
      }
    }
  }
  return groupSchedules;
}

/* ===================== OVERALL SCHEDULING HELPER ===================== */

// Optimizes scheduling by combining complete pairings from all groups for the overall schedule.
function validateAndOptimizeSetup(groups, fields, roundInterval) {
  const setup = [];
  groups.forEach((group) => {
    const groupMatches = generateAllPairs(group);
    setup.push(...groupMatches);
  });
  const optimizedSetup = [];
  let currentTime = 0;
  const lastPlayed = {};
  const usedTeamsInSlot = new Set();
  const teamsPlayed = new Set();

  while (setup.length > 0) {
    const matchesThisRound = [];
    usedTeamsInSlot.clear();

    // Sort matches to favor teams that have waited longer.
    setup.sort(([t1, t2], [tA, tB]) => {
      const p1 = (teamsPlayed.has(t1) ? 1 : 0) + (teamsPlayed.has(t2) ? 1 : 0);
      const p2 = (teamsPlayed.has(tA) ? 1 : 0) + (teamsPlayed.has(tB) ? 1 : 0);
      if (p1 !== p2) return p1 - p2;
      const r1 = Math.min(currentTime - (lastPlayed[t1] || 0), currentTime - (lastPlayed[t2] || 0));
      const r2 = Math.min(currentTime - (lastPlayed[tA] || 0), currentTime - (lastPlayed[tB] || 0));
      return r2 - r1;
    });

    for (const field of fields) {
      let idx = setup.findIndex(([t1, t2]) => {
        return (
          !usedTeamsInSlot.has(t1) &&
          !usedTeamsInSlot.has(t2) &&
          (!lastPlayed[t1] || lastPlayed[t1] < currentTime - roundInterval) &&
          (!lastPlayed[t2] || lastPlayed[t2] < currentTime - roundInterval)
        );
      });
      if (idx !== -1) {
        const match = setup.splice(idx, 1)[0];
        matchesThisRound.push({ field, match });
        usedTeamsInSlot.add(match[0]);
        usedTeamsInSlot.add(match[1]);
        teamsPlayed.add(match[0]);
        teamsPlayed.add(match[1]);
        lastPlayed[match[0]] = currentTime;
        lastPlayed[match[1]] = currentTime;
      }
    }
    if (matchesThisRound.length > 0) {
      optimizedSetup.push({ time: formatTime(currentTime), matches: matchesThisRound });
      currentTime += roundInterval;
    } else {
      // Insert an empty round to allow teams to rest.
      currentTime += roundInterval;
    }
  }
  return optimizedSetup;
}

/* ===================== SCHEDULE GENERATION ===================== */

// Generates the schedule from current group assignments.
// The overall schedule (for Tournament and Fields) uses validateAndOptimizeSetup.
// The group schedules are built separately and then reordered to avoid consecutive games.
function generateScheduleFromAssignments(assignments, fields, matchDuration, breakDuration, tournamentDate, tournamentTime) {
  const groups = [];
  const teamGroupMap = {};
  for (const groupName in assignments) {
    const teamNames = assignments[groupName].map(team => team.text);
    groups.push(teamNames);
    teamNames.forEach(name => {
      teamGroupMap[name] = groupName;
    });
  }
  const startTimeObj = new Date(`${tournamentDate}T${tournamentTime}`);
  const startMinutes = startTimeObj.getHours() * 60 + startTimeObj.getMinutes();

  // Build raw group schedules (before reordering) for each group.
  const groupSchedules = {};
  groups.forEach((group, idx) => {
    const groupName = `Group ${idx + 1}`;
    groupSchedules[groupName] = [];
    const pairs = generateAllPairs(group);
    // Build raw matches without time (times will be reassigned after reordering).
    pairs.forEach((match, matchIndex) => {
      const field = fields[matchIndex % fields.length];
      groupSchedules[groupName].push({ field, teams: match });
    });
    // Now reorder to avoid consecutive games by the same team and assign times.
    groupSchedules[groupName] = reorderGroupSchedule(
      groupSchedules[groupName],
      startMinutes,
      matchDuration,
      breakDuration
    );
  });
  // Mark back-to-back rounds for groups if needed.
  markBackToBackForGroupSchedules(groupSchedules);

  // Build overall schedule (Tournament Schedule and Schedule by Fields)
  const optimizedSetup = validateAndOptimizeSetup(groups, fields, matchDuration + breakDuration);
  const schedule = [];
  let currentTime = startMinutes;
  while (optimizedSetup.length > 0) {
    const round = optimizedSetup.shift();
    const matches = [];
    for (const matchInfo of round.matches) {
      const [t1, t2] = matchInfo.match;
      matches.push({
        field: matchInfo.field,
        teams: [t1, t2],
        groups: [teamGroupMap[t1], teamGroupMap[t2]]
      });
    }
    if (matches.length > 0) {
      schedule.push({ time: formatTime(currentTime), matches });
    }
    currentTime += matchDuration + breakDuration;
  }
  // Do not mark back-to-back on the overall schedule.
  return { schedule, groupSchedules };
}

// Generates the initial tournament schedule from total teams.
function generateTournamentSchedule(numberOfTeams, fields, matchDuration, breakDuration, startTime) {
  const teams = Array.from({ length: numberOfTeams }, (_, i) => ({
    id: `${i + 1}`,
    text: `Team ${i + 1}`
  }));
  const startMinutes = startTime.getHours() * 60 + startTime.getMinutes();
  let numberOfGroups = Math.ceil(numberOfTeams / 4);
  while (numberOfTeams / numberOfGroups < 4) {
    numberOfGroups--;
  }
  const groups = [];
  const teamGroupMap = {};
  for (let i = 0; i < numberOfGroups; i++) {
    groups.push([]);
  }
  teams.forEach((team, index) => {
    const groupIndex = index % numberOfGroups;
    groups[groupIndex].push(team);
    teamGroupMap[team.text] = `Group ${groupIndex + 1}`;
  });
  const groupSchedules = {};
  groups.forEach((group, index) => {
    const groupName = `Group ${index + 1}`;
    groupSchedules[groupName] = [];
    const pairs = generateAllPairs(group.map(team => team.text));
    pairs.forEach((match, matchIndex) => {
      const field = fields[matchIndex % fields.length];
      groupSchedules[groupName].push({ field, teams: match });
    });
    groupSchedules[groupName] = reorderGroupSchedule(
      groupSchedules[groupName],
      startMinutes,
      matchDuration,
      breakDuration
    );
  });
  markBackToBackForGroupSchedules(groupSchedules);

  const optimizedSetup = validateAndOptimizeSetup(
    groups.map(group => group.map(team => team.text)),
    fields,
    matchDuration + breakDuration
  );
  const schedule = [];
  let currentTime = startMinutes;
  while (optimizedSetup.length > 0) {
    const round = optimizedSetup.shift();
    const matches = [];
    for (const matchInfo of round.matches) {
      const [t1, t2] = matchInfo.match;
      matches.push({
        field: matchInfo.field,
        teams: [t1, t2],
        groups: [teamGroupMap[t1], teamGroupMap[t2]]
      });
    }
    if (matches.length > 0) {
      schedule.push({ time: formatTime(currentTime), matches });
    }
    currentTime += matchDuration + breakDuration;
  }
  return { schedule, groupSchedules, groups };
}

// Creates group schedules from current group assignments (for final schedule generation).
function createGroupSchedulesFromTeams(assignments, tournamentDate, tournamentTime, numberOfFields, matchDuration, breakDuration) {
  const newGS = {};
  const startTimeObj = new Date(`${tournamentDate}T${tournamentTime}`);
  const startMinutes = isNaN(startTimeObj.getTime())
    ? 0
    : startTimeObj.getHours() * 60 + startTimeObj.getMinutes();
  const fieldArray = Array.from({ length: numberOfFields }, (_, i) => `Field ${i + 1}`);
  for (const [groupName, teamArray] of Object.entries(assignments)) {
    newGS[groupName] = [];
    const teamTexts = teamArray.map(team => team.text);
    const pairs = generateAllPairs(teamTexts);
    const rawMatches = [];
    pairs.forEach((match, idx) => {
      const field = fieldArray[idx % fieldArray.length];
      rawMatches.push({ field, teams: match });
    });
    newGS[groupName] = reorderGroupSchedule(rawMatches, startMinutes, matchDuration, breakDuration);
  }
  markBackToBackForGroupSchedules(newGS);
  return newGS;
}

// Builds an overall schedule from computed group schedules.
function buildScheduleFromGroupSchedules(gs, fields, startMinutes, matchDuration, breakDuration) {
  const groupArrays = [];
  const teamGroupMap = {};
  for (const [groupName, matches] of Object.entries(gs)) {
    const teamSet = new Set();
    matches.forEach(m => m.teams && m.teams.forEach(t => teamSet.add(t)));
    groupArrays.push(Array.from(teamSet));
    Array.from(teamSet).forEach(t => {
      teamGroupMap[t] = groupName;
    });
  }
  const optimizedSetup = validateAndOptimizeSetup(groupArrays, fields, matchDuration + breakDuration);
  const newSchedule = [];
  let currentTime = startMinutes;
  while (optimizedSetup.length > 0) {
    const currentRound = optimizedSetup.shift();
    const matchesThisRound = [];
    for (const matchInfo of currentRound.matches) {
      const [team1, team2] = matchInfo.match;
      matchesThisRound.push({
        field: matchInfo.field,
        teams: [team1, team2],
        groups: [teamGroupMap[team1], teamGroupMap[team2]]
      });
    }
    if (matchesThisRound.length > 0) {
      newSchedule.push({ time: formatTime(currentTime), matches: matchesThisRound });
    }
    currentTime += matchDuration + breakDuration;
  }
  return newSchedule;
}

/* ===================== MAIN COMPONENT ===================== */

export default function TournamentSchedule() {
  // Tournament settings.
  const [numberOfTeams, setNumberOfTeams] = useState(25);
  const [numberOfFields, setNumberOfFields] = useState(6);
  const [breakDuration, setBreakDuration] = useState(5);
  const [matchDuration, setMatchDuration] = useState(10);
  const [tournamentDate, setTournamentDate] = useState("2025-02-07");
  const [tournamentTime, setTournamentTime] = useState("10:00");

  // Schedule-related states.
  const [schedule, setSchedule] = useState([]); // overall schedule.
  const [groupSchedules, setGroupSchedules] = useState({});

  // groupAssignments holds the teams in each group for the drag–drop UI.
  const [groupAssignments, setGroupAssignments] = useState({});

  // ------------------- Generate Initial Groups & Schedule -------------------
  const handleGenerateSchedule = () => {
    const fieldArray = Array.from({ length: numberOfFields }, (_, i) => `Field ${i + 1}`);
    const startTimeObj = new Date(`${tournamentDate}T${tournamentTime}`);
    if (isNaN(startTimeObj.getTime())) {
      alert("Please enter a valid date and time.");
      return;
    }
    const { schedule, groupSchedules, groups } = generateTournamentSchedule(
      numberOfTeams,
      fieldArray,
      matchDuration,
      breakDuration,
      startTimeObj
    );
    setSchedule(schedule);
    setGroupSchedules(groupSchedules);
    // Initialize groupAssignments from the generated groups.
    const assignments = {};
    groups.forEach((group, idx) => {
      assignments[`Group ${idx + 1}`] = group;
    });
    setGroupAssignments(assignments);
  };

  // ------------------- Add a New Group -------------------
  const handleAddGroup = () => {
    const groupNames = Object.keys(groupAssignments);
    let highestNum = 0;
    groupNames.forEach(name => {
      const m = name.match(/Group\s+(\d+)/);
      if (m) highestNum = Math.max(highestNum, parseInt(m[1], 10));
    });
    const newGroupName = `Group ${highestNum + 1}`;
    const updatedAssignments = { ...groupAssignments, [newGroupName]: [] };
    setGroupAssignments(updatedAssignments);
    const fieldArray = Array.from({ length: numberOfFields }, (_, i) => `Field ${i + 1}`);
    const newGS = createGroupSchedulesFromTeams(
      updatedAssignments,
      tournamentDate,
      tournamentTime,
      numberOfFields,
      matchDuration,
      breakDuration
    );
    setGroupSchedules(newGS);
  };

  // ------------------- Delete a Group -------------------
  const handleDeleteGroup = (groupName) => {
    const updatedAssignments = { ...groupAssignments };
    delete updatedAssignments[groupName];
    setGroupAssignments(updatedAssignments);
    const fieldArray = Array.from({ length: numberOfFields }, (_, i) => `Field ${i + 1}`);
    const newGS = createGroupSchedulesFromTeams(
      updatedAssignments,
      tournamentDate,
      tournamentTime,
      numberOfFields,
      matchDuration,
      breakDuration
    );
    setGroupSchedules(newGS);
  };

  // ------------------- Move a Team (Drag & Drop) -------------------
  const moveTeam = (team, fromGroup, toGroup) => {
    if (!fromGroup || !toGroup || fromGroup === toGroup) return;
    if (!team || typeof team !== 'object' || !team.id) {
      console.error("Invalid team object:", team);
      return;
    }
    const updated = { ...groupAssignments };
    updated[fromGroup] = updated[fromGroup].filter(t => t.id !== team.id);
    updated[toGroup] = [...updated[toGroup], team];
    setGroupAssignments(updated);
  };

  // ------------------- Finalize & Generate Schedule -------------------
  const handleFinalizeSchedule = () => {
    const fieldArray = Array.from({ length: numberOfFields }, (_, i) => `Field ${i + 1}`);
    const { schedule: newSchedule, groupSchedules: newGroupSchedules } = generateScheduleFromAssignments(
      groupAssignments,
      fieldArray,
      matchDuration,
      breakDuration,
      tournamentDate,
      tournamentTime
    );
    console.log("Updated groupAssignments:", groupAssignments);
    console.log("New schedule:", newSchedule);
    console.log("New groupSchedules:", newGroupSchedules);
    setSchedule(newSchedule);
    setGroupSchedules(newGroupSchedules);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <ScrollView style={styles.container}>
        <Text style={styles.header}>Tournament Setup</Text>

        {/* Basic Inputs */}
        <View style={styles.row}>
          <View style={styles.inputGroup}>
            <Text style={styles.label}>Date (YYYY-MM-DD)</Text>
            <TextInput
              style={styles.input}
              placeholder="e.g. 2025-01-31"
              placeholderTextColor="#aaa"
              value={tournamentDate}
              onChangeText={setTournamentDate}
              keyboardType="numbers-and-punctuation"
            />
          </View>
          <View style={styles.inputGroup}>
            <Text style={styles.label}>Time (HH:MM)</Text>
            <TextInput
              style={styles.input}
              placeholder="e.g. 14:30"
              placeholderTextColor="#aaa"
              value={tournamentTime}
              onChangeText={setTournamentTime}
              keyboardType="numbers-and-punctuation"
            />
          </View>
        </View>
        <View style={styles.inputGroup}>
          <Text style={styles.label}>Number of Teams</Text>
          <TextInput
            style={styles.input}
            keyboardType="number-pad"
            value={numberOfTeams.toString()}
            onChangeText={(text) => setNumberOfTeams(Number(text))}
          />
        </View>
        <View style={styles.inputGroup}>
          <Text style={styles.label}>Number of Fields</Text>
          <TextInput
            style={styles.input}
            keyboardType="number-pad"
            value={numberOfFields.toString()}
            onChangeText={(text) => setNumberOfFields(Number(text))}
          />
        </View>
        <View style={styles.inputGroup}>
          <Text style={styles.label}>Match Duration (minutes)</Text>
          <TextInput
            style={styles.input}
            keyboardType="number-pad"
            value={matchDuration.toString()}
            onChangeText={(text) => setMatchDuration(Number(text))}
          />
        </View>
        <View style={styles.inputGroup}>
          <Text style={styles.label}>Break Duration (minutes)</Text>
          <TextInput
            style={styles.input}
            keyboardType="number-pad"
            value={breakDuration.toString()}
            onChangeText={(text) => setBreakDuration(Number(text))}
          />
        </View>

        <TouchableOpacity style={styles.button} onPress={handleGenerateSchedule}>
          <Text style={styles.buttonText}>Generate Initial Groups & Schedule</Text>
        </TouchableOpacity>

        <Text style={styles.sectionHeader}>Teams & Their Groups (Drag & Drop)</Text>
        <TouchableOpacity style={styles.addGroupButton} onPress={handleAddGroup}>
          <Text style={styles.addGroupButtonText}>+ Add New Group</Text>
        </TouchableOpacity>
        {Object.keys(groupSchedules).length === 0 ? (
          <Text style={styles.match}>No groups yet. Generate initial groups first.</Text>
        ) : (
          <View style={styles.groupsContainer}>
            {Object.entries(groupAssignments).map(([groupName, teams]) => (
              <View key={groupName} style={styles.groupWrapper}>
                <Box boxName={groupName} items={teams} moveItem={moveTeam} />
                {teams.length === 0 && (
                  <TouchableOpacity
                    style={styles.deleteButton}
                    onPress={() => {
                      handleDeleteGroup(groupName);
                      handleFinalizeSchedule();
                    }}
                  >
                    <Text style={styles.deleteButtonText}>Delete Group</Text>
                  </TouchableOpacity>
                )}
              </View>
            ))}
          </View>
        )}

        <TouchableOpacity style={styles.button} onPress={handleFinalizeSchedule}>
          <Text style={styles.buttonText}>Finalize & Generate Schedule</Text>
        </TouchableOpacity>

        <Text style={styles.sectionHeader}>📅 Tournament Schedule</Text>
        {schedule && schedule.map((slot) => (
          <View key={slot.time} style={styles.card}>
            <Text style={styles.time}>⏰ Time: {slot.time}</Text>
            {slot.matches.map((match, matchIndex) => (
              <View key={`${match.field}-${matchIndex}`}>
                <Text style={styles.match}>
                  {match.field}: {match.teams[0]} ({match.groups[0]}) vs {match.teams[1]} ({match.groups[1]})
                </Text>
                {match.backToBack && (
                  <Text style={styles.backToBack}>Back-to-back</Text>
                )}
              </View>
            ))}
          </View>
        ))}

        <Text style={styles.sectionHeader}>👥 Schedule by Groups</Text>
        {Object.entries(groupSchedules).map(([groupName, matches]) => (
          <View key={groupName} style={styles.scheduleGroupContainer}>
            <Text style={styles.scheduleGroupHeader}>{groupName}</Text>
            {matches.map((match, matchIndex) => (
              <View key={`${groupName}-match-${matchIndex}`}>
                {match.gap ? (
                  <Text style={styles.match}>Time: {match.time} — Rest Round</Text>
                ) : (
                  <Text style={styles.match}>
                    Time: {match.time}, Field: {match.field}, Teams: {match.teams[0]} vs {match.teams[1]}
                  </Text>
                )}
                {match.backToBack && (
                  <Text style={styles.backToBack}>Back-to-back</Text>
                )}
              </View>
            ))}
          </View>
        ))}

        <Text style={styles.sectionHeader}>📍 Schedule by Fields</Text>
        {Array.from({ length: numberOfFields }, (_, i) => `Field ${i + 1}`).map((field) => (
          <View key={field} style={styles.scheduleGroupContainer}>
            <Text style={styles.scheduleGroupHeader}>{field}</Text>
            {schedule
              .flatMap((slot) => slot.matches)
              .filter((match) => match.field === field)
              .map((match, matchIndex) => (
                <View key={`${field}-match-${matchIndex}`}>
                  <Text style={styles.match}>
                    Time: {schedule.find((slot) => slot.matches.includes(match))?.time},{" "}
                    Teams: {match.teams[0]} vs {match.teams[1]}
                  </Text>
                  {match.backToBack && (
                    <Text style={styles.backToBack}>Back-to-back</Text>
                  )}
                </View>
              ))}
          </View>
        ))}
      </ScrollView>
    </DndProvider>
  );
}

/* ===================== STYLES ===================== */
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: "#f5f7fb",
  },
  header: {
    fontSize: 28,
    fontWeight: "bold",
    color: "#004080",
    marginBottom: 20,
    textAlign: "center",
  },
  sectionHeader: {
    fontSize: 22,
    fontWeight: "bold",
    color: "#003366",
    marginVertical: 20,
  },
  inputGroup: {
    marginBottom: 15,
  },
  label: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#004080",
    marginBottom: 5,
  },
  input: {
    backgroundColor: "#fff",
    padding: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    fontSize: 14,
    color: "#333",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#004080",
    padding: 15,
    borderRadius: 10,
    alignItems: "center",
    marginBottom: 20,
  },
  buttonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "bold",
  },
  match: {
    fontSize: 16,
    color: "#333",
  },
  card: {
    backgroundColor: "#fff",
    padding: 15,
    borderRadius: 10,
    marginBottom: 15,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
  },
  time: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#004080",
    marginBottom: 10,
  },
  addGroupButton: {
    backgroundColor: "#008080",
    padding: 10,
    alignSelf: "flex-start",
    borderRadius: 6,
    marginBottom: 15,
  },
  addGroupButtonText: {
    color: "#fff",
    fontWeight: "bold",
  },
  scheduleGroupContainer: {
    backgroundColor: "#eaf4ff",
    padding: 15,
    borderRadius: 10,
    marginBottom: 20,
  },
  scheduleGroupHeader: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#003366",
    marginBottom: 10,
  },
  groupsContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: 10,
  },
  box: {
    width: 150,
    minHeight: 150,
    margin: 10,
    padding: 10,
    backgroundColor: "#f5f5f5",
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 8,
    alignItems: "center",
  },
  boxTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  itemBox: {
    width: '100%',
    padding: 8,
    backgroundColor: '#ddd',
    marginVertical: 5,
    borderRadius: 5,
    alignItems: 'center',
  },
  itemText: {
    fontSize: 16,
    color: '#333',
  },
  dragging: {
    opacity: 0.5,
  },
  emptyText: {
    fontStyle: 'italic',
    color: '#888',
  },
  teamBox: {
    width: "100%",
    backgroundColor: "#f0f0f0",
    padding: 8,
    marginBottom: 8,
    borderRadius: 8,
    alignItems: "center",
  },
  teamBoxText: {
    color: "#333",
  },
  draggingStyle: {
    opacity: 0.4,
  },
  placeholderText: {
    fontStyle: "italic",
    color: "#888",
  },
  groupWrapper: {
    marginBottom: 20,
    alignItems: 'center',
  },
  deleteButton: {
    marginTop: 5,
    backgroundColor: '#ff4444',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
  },
  deleteButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  backToBack: {
    color: 'red',
    fontWeight: 'bold',
    marginLeft: 10,
  },
});

export { TournamentSchedule };
